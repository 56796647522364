<template>
  <mobile-screen
    :header="true"
    screen-class="resources-screen gray-bg icon-app1"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="resource-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{
            displayLabelName("resources", "resource-types", "resource-types")
          }}
        </div>
      </top-header-menu-wrapper>
      <section class="search-wrapper time-zone-search">
        <div class="search-field">
          <input
            type="text"
            @keyup="searchQuery = $event.target.value"
            :placeholder="
              displayLabelName('resources', 'resource-types', 'search')
            "
          />
          <div class="icon search">
            <icon icon="#cx-app1-search-16x16" width="16" height="16" />
          </div>
        </div>
      </section>
    </template>
    <ul
      class="clebex-item-section"
      v-if="listOfResourceTypes && listOfResourceTypes.length"
    >
      <li class="clebex-item-section-item">
        <div class="clebex-item-content-wrapper">
          <dl class="clebex-item-dl justify-start">
            <dt class="clebex-item-dt">
              <div class="checkbox slide">
                <input
                  id="timeZoneRadioNotDefined"
                  type="checkbox"
                  v-model="notDefined"
                />
                <label for="timeZoneRadioNotDefined"></label>
              </div>
            </dt>
            <dd class="clebex-item-dd justify-start">
              {{
                displayLabelName("resources", "resource-types", "not-defined")
              }}
            </dd>
          </dl>
        </div>
      </li>
    </ul>
    <ul
      class="clebex-item-section pill"
      v-if="listOfResourceTypes && listOfResourceTypes.length"
    >
      <li
        class="clebex-item-section-item"
        v-for="resourceType in listOfResourceTypes"
        :key="resourceType.id"
      >
        <a
          href="#"
          class="clebex-item-content-wrapper"
          @click.prevent="selectResource(resourceType)"
        >
          <span class="label">
            <span class="highlight">{{ resourceType.name }}</span>
          </span>
        </a>
      </li>
    </ul>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";
export default {
  name: "ResourceDynamicCapacity",
  data() {
    return {
      notDefined: false,
      searchQuery: ""
    };
  },
  computed: {
    ...mapState("resource", [
      "resourceCapacityTypes",
      "selectedResourceCapacityType"
    ]),
    listOfResourceTypes() {
      const query = this.searchQuery;
      if (
        this.resourceCapacityTypes &&
        this.resourceCapacityTypes.data &&
        this.resourceCapacityTypes.data.length
      ) {
        if (query && query.length > 1) {
          return this.resourceCapacityTypes.data.filter(item =>
            item.name.toLowerCase().includes(query.toLowerCase())
          );
        } else {
          return this.resourceCapacityTypes.data;
        }
      }
      return null;
    }
  },
  created() {
    this.getResourceCapacityTypes();
  },
  watch: {
    notDefined() {
      if (this.notDefined) {
        this.setSelectedResourceCapacityType(null);
      }
    }
  },
  methods: {
    ...mapActions("resource", [
      "getResourceCapacityTypes",
      "setSelectedResourceCapacityType"
    ]),
    selectResource(resource) {
      this.setSelectedResourceCapacityType(resource);
      if (resource.type === "DATE")
        this.$router.push({ name: this.dateLinkName });
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    },
    dateLinkName: {
      type: String,
      required: true
    }
  }
};
</script>
